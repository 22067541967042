import Cookies from 'js-cookie';

const state = {
  loggedIn: Cookies.get('pro_session_expiration') === "1",
};

// getters
const getters = {
  LoggedIn: state => state.loggedIn,
};

// actions
const actions = {
  setLoggedIn({commit}, data) {
    commit('setLoggedIn', data);
  },
};

// mutations
const mutations = {
  setLoggedIn(state, data) {
    state.loggedIn = data; // Korrektur: Ändere "this.loggedIn" zu "state.loggedIn"
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
