const state = {
  choices: ['silver', 'silver', 'silver', 'silver', 'silver', 'silver'],
};


// getters
const getters = {
  activeServers: state => state.choices
};

// actions
const actions = {
  setChoices({commit}, data) {
    commit('setChoices', data)
  },
};

const mutations = {
  setChoices(state, data) {
    this.choices = data
  },

};

export default {
  state,
  getters,
  actions,
  mutations
}
