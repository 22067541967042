import Vue from 'vue'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import {iconsSet as icons} from './assets/icons/icons.js'
import {ApolloClient} from 'apollo-client'
import {onError} from 'apollo-link-error'
import {createHttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import {ApolloLink} from 'apollo-link'
import store from './store'
import Servers from './_servers'
import i18n from './lang/lang'
import VueContentPlaceholders from 'vue-content-placeholders'
import * as onLoad from 'vue-onload'
import {split} from 'apollo-link'
import {getMainDefinition} from 'apollo-utilities'
import {WebSocketLink} from 'apollo-link-ws'
import VueLadda from './views/vue-ladda'
import VueNotifications from 'vue-notifications'
import iziToast from 'izitoast'// https://github.com/dolce/iziToast
import 'izitoast/dist/css/iziToast.min.css'

const warningTitleCSS = 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;'
const warningDescCSS = 'font-size: 18px;'

console.log('%cStop!', warningTitleCSS)
console.log("%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a PRO feature or \"hack\" someone's account, it is a scam and will give them access to your PRO account.", warningDescCSS)
console.log('%cSee https://en.wikipedia.org/wiki/Self-XSS for more information.', warningDescCSS)

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = 'warning';
  return iziToast[type]({title, message, timeout, transitionIn: 'bounceInLeft'})
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
console.log( process.env.VUE_APP_API_URL)
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri:  process.env.VUE_APP_API_URL,
});

// Create the subscription websocket link
const wsLink = new WebSocketLink({
  uri:  process.env.VUE_APP_WS_URL,
  options: {
    reconnect: true,
  },
});



// Cache implementation
const cache = new InMemoryCache();
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'Accept-Language': store.getters.Lang
    }
  });
  return forward(operation)
});



const errorLink = onError(({operation, response, graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.map(({message, locations, path}) => {
      if (message === 'authentication required') {
        store.dispatch('setLoggedIn', false)
        const currentPath = router.currentRoute.fullPath;  // No .value in Vue 2
        // Redirect to the login page with the current path as a redirect query
        if (router.currentRoute.path !== '/auth/login') {
          router.push({
            path: '/auth/login',
            query: {redirect: currentPath}
          });
        }
      }
      }
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = ApolloLink.from([errorLink, split(
  // split based on operation type
  ({query}) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
  },
  wsLink,
  httpLink)
]);


// Create the apollo client
const Backend = new ApolloClient({
  link: authMiddleware.concat(link),
  cache,
});





const apolloProvider = new VueApollo({
  defaultClient: Backend,
  clients: {Backend}
});

Vue.use(VueNotifications, options);
Vue.use(VueApollo);
Vue.use(CoreuiVuePro)
Vue.prototype.$log = console.log.bind(console)

Vue.use(VueContentPlaceholders);
Vue.component('vue-ladda', VueLadda);



router.beforeEach((to, from, next) => {
  if (store.getters.LoggedIn || to.name === "login") {
    next()
  } else {
    next({path: 'auth/login', query: { redirect: to.fullPath }})
  }
});
Vue.mixin(Servers)

Vue.use(onLoad.plugin)
/* eslint-disable no-new */
export const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  apolloProvider,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: '<App/>',
  components: {
    App
  },
  notifications: {
    ForceLoggedOut: {
      type: 'info',
      title: 'Logged out',
      message: 'You have been logged out due to security reasons.'
    },
  }
});


function getFirstBrowserLanguage () {
  let nav = window.navigator,
    browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
    i,
    language;

  // support for HTML 5.1 "navigator.languages"
  if (Array.isArray(nav.languages)) {
    for (i = 0; i < nav.languages.length; i++) {
      language = nav.languages[i];
      if (language && language.length) {
        return language;
      }
    }
  }

  // support for other well known properties in browsers
  for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
    language = nav[browserLanguagePropertyKeys[i]];
    if (language && language.length) {
      return language;
    }
  }

  return "";
}
store.dispatch('fetchAccountLang');

if (store.getters.Lang === null) {
  let language = getFirstBrowserLanguage().split('-')[0];
  if (['en', 'fr', 'de', 'it', 'es', 'br', 'gr'].indexOf(language) > 0) {
    store.dispatch('setLangNew', language)
  }
} else {
  // load the selected language
  store.dispatch('setLangNew', store.getters.Lang)
}


