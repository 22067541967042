import {app} from '../../main'
import moment from 'moment'
import ChangeLanguage from "@/graphql/ChangeUserLanguage.graphql";
import AccountLang from "@/graphql/AccountLang.graphql";

export const SET_LANG = 'SET_LANG';

function getUnicodeLang(lang) {
  if (lang === 'gr') {
    return 'el'
  }
  if (lang === 'br') {
    return 'pt-br'
  }
  return lang
}

export const state = {
  lang: null
};

export const getters = {
  Lang: state => state.lang ?  state.lang : 'en',
  LangUnicode: state => getUnicodeLang(state.lang)
};

export const mutations = {
  [SET_LANG](state, payload) {
    app.$i18n.locale = payload;
    state.lang = payload;
    moment.locale(getUnicodeLang(payload))
  }
};

export const actions = {
  async fetchAccountLang({ commit }) {
    try {
      const response = await app.$apollo.query({
        query: AccountLang,
      });

      const accountLang = response.data.accountLang;

      // Dispatch the mutation to update the state
      commit('SET_LANG', accountLang);
    } catch (error) {
      console.error('Error fetching accountLang:', error);
    }
  },
  setLang({commit}, payload) {
    commit(SET_LANG, payload)
  },
  async setLangNew({commit}, payload) {
    await app.$apollo.mutate({
      mutation: ChangeLanguage,
      variables: {
        lang: payload
      }
    })
    if (payload in app.$i18n.messages) {
      commit(SET_LANG, payload)
    } else {
      try {
        const res = await import(`../../lang/locale/${payload}.json`);
        app.$i18n.setLocaleMessage(payload, res);
        commit(SET_LANG, payload)
      } catch (e) {
        console.log(e)
      }
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}
