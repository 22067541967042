
export default {
  data() {
    return {
      servers: [
        {
          name: 'silver',
          colour: '#999999'

        },
        {
          name: 'gold',
          colour: '#a8965c'
        }
      ]
    }
  },

}


