import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard = () => import('@/views/Dashboard');

const Donations = () => import('@/views/DonationHistory');
const InfractionHistory = () => import('@/views/InfractionHistory');

const Profile = () => import('@/views/profile/Profile');
const Password = () => import('@/views/profile/Password');
const Email = () => import('@/views/profile/Email');
const Username = () => import('@/views/profile/Username');
const Discord = () => import ('@/views/profile/Discord');
const ChangeEmail = () => import('@/views/ChangeEmail');
const Privacy = () => import('@/views/Privacy');
const Login = () => import('@/views/Login');
const ServerTransfer = () => import('@/views/tools/ServerTransfer');
const Credits = () => import('@/views/Credits');


Vue.use(Router);

export default new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({y: 0}),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard
        },
        {
          path: 'donations',
          name: 'donation_history',
          component: Donations
        },
        {
          path: 'transfer',
          name: 'server_transfer',
          component: ServerTransfer
        },
        {
          path: 'profile',
          redirect: '/profile/overview',
          name: 'profile',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'overview',
              name: 'overview',
              component: Profile
            },
            {
              path: 'change-password',
              name: 'change_password',
              component: Password
            },
            {
              path: 'change-username',
              name: 'change_username',
              component: Username
            },
            {
              path: 'change-email',
              name: 'change_email',
              component: Email
            },
            {
              path: 'discord',
              name: 'discord_tag',
              component: Discord
            }
          ]
        },
        {
          path: 'change-email/:token',
          name: 'change_email',
          component: ChangeEmail
        },
        {
          path: 'infractions',
          name: 'infraction_history',
          component: InfractionHistory
        },
        {
          path: 'privacy',
          name: 'privacy_settings',
          component: Privacy
        },
        {
          path: 'credits',
          name: 'credits',
          component: Credits
        }
      ]
    },
    {
      path: '/auth',
      redirect: '/auth/login',
      name: 'auth',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login
        }
      ]
    }]
})
